<template>
    <main class="main mt-8">
        <div class="container">
            <div class="row">
                <div class="col-9 order-lg-last">
                    <h2>العناوين</h2><hr>

                    <div class="addresses">
                        <div v-for="item in addresses" :key="item.id" class="card ml-4">
                            <div class="card-header bg-transparent d-flex justify-content-between align-items-sm-center">
                                <div class="form-check">
                                    <input
                                        type="radio"
                                        :id="item.id"
                                        class="form-check-input mr-2"
                                        name="default-address"
                                        v-if="!item.isDefault"
                                        @click="set_default(item.id)"
                                    >
                                    <div v-else>
                                        <input
                                            type="radio"
                                            :id="item.id"
                                            name="default-address"
                                            class="form-check-input mr-2"
                                            checked
                                            @click="set_default(item.id)"
                                        >
                                        <label class="form-check-label" :for="item.id">
                                            <p class="mb-0">افتراضي</p>
                                        </label>
                                    </div>
                                </div>
                                <p class="mb-0 w-100">{{item.address}}</p>
                            </div>
                            <div class="card-body text-right">
                                <h5 class="card-title">{{item.zoneName}}</h5>
                                <h6 class="card-subtitle mb-2 text-muted">{{item.details}}</h6>
                                <p>{{item.phone}}</p>
                            </div>
                            <div class="card-footer bg-transparent">
                                <a href="#" @click.prevent="delete_address(item.id)" class="card-link ml-3">حذف</a>
                                <a href="#" @click.prevent="get_address(item.id)" class="card-link">تعديل</a>
                            </div>
                        </div>
                    </div>

                    <p v-if="!addresses.length" class="h2 text-center">لا توجد عناوين</p><hr class="mb-3">

                    <div class="btn btn-primary btn-block mr-2"
                        @click="$refs['new-address'].show()">
                        اضف عنوان جديد
                    </div>

                    <b-modal size="lg" ref="new-address" hide-footer centered dir="rtl" id="new-address">
                        <template #modal-header>
                            <h2>عنوان جديد</h2>
                        </template>
                        <form class="form-group required-field" @submit.prevent="new_address">
                            <div>
                                <p class="h4 mb-1">اسم العنوان</p>
                                <input type="text" class="form-control mb-4" v-model="address.address" id="address" name="address" placeholder="مثال: المنزل، العمل.." required>

                                <h2 class="h4 mb-1">المنطقة</h2>
                                <select class="form-control mb-4 form-select" name="zones" v-model="address.zone" required>
                                    <option value="" selected disabled>الرجاء اختيار منطقة</option>
                                    <option class="form-control" v-for="zone in zones" :key="zone.id" :value="zone">{{ zone.name }}</option>
                                </select>

                                <div class="form-group mb-2">
                                    <p class="h4 mb-1">التفاصيل</p>
                                    <textarea id="details" v-model="address.details" cols="5" rows="6" class="form-control form-control-sm mb-4"></textarea>
                                </div>

                                <h2 class="h4">رقم الهاتف</h2>
                                <div class="phone">
                                    <input id="phone" type="tel" class="form-control" v-model="address.phone" placeholder="218-9xxxxxxxx+" required>

                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" @click="address.is_default =! address.is_default" class="custom-control-input" id="is_default" name="set-default" value="is_default">
                                        <label class="custom-control-label" for="is_default">اختيار كعنوان افتراضي</label>
                                    </div>
                                </div>
                                <!-- <p v-if="this.error.phone.show" class="text-danger">{{ this.error.phone.msg }} ({{this.counter}})</p><hr class="mb-2 mt-3"> -->
                                <b-button type="submit">تأكيد</b-button>
                                <b-button @click="$bvModal.hide('new-address'), reset_address()" class="mr-4">الغاء</b-button>
                            </div>
                        </form>
                    </b-modal>

                    <b-modal size="lg" ref="edit-address" hide-footer centered dir="rtl" id="edit-address">
                        <template #modal-header>
                            <h2>تعديل عنوان</h2>
                        </template>
                        <form class="form-group required-field" @submit.prevent="edit_address">
                            <div>
                                <p class="h4 mb-1">اسم العنوان</p>
                                <input type="text" class="form-control mb-4" v-model="address.address" id="address" name="address" placeholder="مثال: المنزل، العمل.." required>

                                <h2 class="h4 mb-1">المنطقة</h2>
                                <select class="form-control mb-4 form-select" name="zones" v-model="address.zone" required>
                                    <option value="" selected disabled>الرجاء اختيار منطقة</option>
                                    <option class="form-control" v-for="zone in zones" :key="zone.id" :value="zone">{{ zone.name }}</option>
                                </select>

                                <div class="form-group mb-2">
                                    <p class="h4 mb-1">التفاصيل</p>
                                    <textarea id="details" v-model="address.details" cols="5" rows="6" class="form-control form-control-sm mb-4"></textarea>
                                </div>

                                <h2 class="h4">رقم الهاتف</h2>
                                <div class="phone">
                                    <input id="phone" type="tel" v-model="address.phone" class="form-control" placeholder="218-9xxxxxxxx+" required>
                                    <div class="custom-control custom-checkbox">
                                        <input v-if="address.is_default" type="checkbox" @click="address.is_default =! address.is_default" class="custom-control-input" id="is_default" name="set-default" value="is_default" checked>
                                        <input v-else type="checkbox" @click="address.is_default =! address.is_default" class="custom-control-input" id="is_default" name="set-default" value="is_default">
                                        <label class="custom-control-label" for="is_default">اختيار كعنوان افتراضي</label>
                                    </div>
                                </div>
                                <!-- <p v-if="this.error.phone.show" class="text-danger">{{ this.error.phone.msg }} ({{this.counter}})</p><hr class="mb-2 mt-3"> -->
                                <b-button type="submit">تأكيد</b-button>
                                <b-button @click="$bvModal.hide('edit-address'), reset_address()" class="mr-4">الغاء</b-button>
                            </div>
                        </form>
                    </b-modal>
                </div>

                <aside class="sidebar col-2">
                    <div class="widget widget-dashboard">
                        <h3 class="widget-title">الحساب</h3>

                        <ul class="list">
                            <li ><a href="/account">معلومات الحساب</a></li>
                            <li><a href="/orders">الطلبيات</a></li>
                            <li><a href="/wallet">المحفظة</a></li>
                            <li class="active"><a @click.prevent>العناوين</a></li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            counter: 0,
            // error: {
            //     phone: {
            //         regex: /^(21892\d{7}|21891\d{7}|21894\d{7}|21895\d{7})$/,
            //         msg: 'الرقم المدخل غير صحيح',
            //         show: false,
            //     },
            // },
            address: {
                id: 0,
                details: '',
                phone: '',
                address: '',
                zone: {},
                is_default: false,
            },
            addresses: [],
            zones: [],
        }
    },
    mounted() {
        this.axios({
            url: 
process.env.VUE_APP_URL+'/api/addresses',
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
        })
        .then(response => {
            console.log(response)
            this.addresses = response.data
        })

        this.axios({
            url: 
process.env.VUE_APP_URL+'/api/zones',
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            this.zones = response.data
        })
    },
    methods: {
        // reset_error: function() {
        //     this.counter = 0
        //     this.error.phone.show = false
        // },
        reset_address: function() {
                    this.address.address = ''
                    this.address.details = ''
                    this.address.is_default = false
                    this.address.phone = ''
                    this.address.zone = {}
        },
        delete_address: function(id) {
            this.axios({
                url: process.env.VUE_APP_URL+`/api/addresses/${id}`,
                method: 'DELETE',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
            })
            .then(() => {
                this.$router.go()
            })
        },
        set_default: function(id) {
            this.axios({
                url: process.env.VUE_APP_URL+`/api/addresses/set-default/${id}`,
                method: 'GET',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
            })
            .then(() => {
                this.$router.go()
            })
        },
        new_address: function() {
          
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/addresses',
                method: 'POST',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                data: {
                    address: this.address.address,
                    customerId: localStorage.getItem('customer_id'),
                    customerName: localStorage.getItem('username'),
                    details: this.address.details,
                    isDefault: this.address.is_default,
                    phone: this.address.phone,
                    zoneId: this.address.zone.id,
                    zoneName: this.address.zone.name
                },
            })
            .then(response => {
                if(this.address.is_default) {
                    this.axios({
                        url: process.env.VUE_APP_URL+`/api/addresses/set-default/${response.data.id}`,
                        method: 'GET',
                        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                    })
                    .then(() => this.$router.go())
                }
                else this.$router.go()
            })
        },
        get_address: function(id) {
            this.address.id = id
            this.axios({
                url: process.env.VUE_APP_URL+`/api/addresses/${id}`,
                method: 'GET',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
            })
            .then(response => {
                    this.address.address = response.data.address
                    this.address.details = response.data.details
                    this.address.is_default = response.data.isDefault
                    this.address.phone = response.data.phone
                    this.address.zone.id = response.data.zoneId
                    this.address.zone.name = response.data.zoneName
                    this.$refs['edit-address'].show()
            })
        },
        edit_address: function() {
            // if(!this.error.phone.regex.test(this.address.phone)) {
            //     this.error.phone.show = true
            //     this.counter++
            //     return
            // }
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/addresses',
                method: 'PUT',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                data: {
                    address: this.address.address,
                    customerId: localStorage.getItem('customer_id'),
                    customerName: localStorage.getItem('username'),
                    details: this.address.details,
                    id: this.address.id,
                    isDefault: this.address.is_default,
                    phone: this.address.phone,
                    zoneId: this.address.zone.id,
                    zoneName: this.address.zone.name
                },
            })
            .then(response => {
                if(this.address.is_default) {
                    this.axios({
                        url: process.env.VUE_APP_URL+`/api/addresses/set-default/${response.data.id}`,
                        method: 'GET',
                        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                    })
                    .then(() => this.$router.go())
                }
                else this.$router.go()
            })
        },
    }
}
</script>

<style scoped>
    .phone {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    .addresses {
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-items: stretch;
        grid-gap: 20px;
    }
    .col-9 {
        background-color: rgb(255, 255, 255);
        border-radius: 1rem;
        padding: 31px !important;
        margin-left: 4rem;
        margin-bottom: 4rem;
    }
</style>